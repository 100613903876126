import { SoundDialog } from "./components/SoundDialog";
import { PreloadErrorDialog } from "./components/PreloadErrorDialog";
import {
	BackgroundType,
	ComponentType,
	GradientDirection,
	HAlign,
	LayoutConfig,
	LayoutMode,
	TextAlign,
	VAlign
} from "@tournament/ui-core";
import { Components, Controllers, HiLoDisplayChild } from "@tournament/hilo-ui-core";

export const PreloaderConfig: LayoutConfig<HiLoDisplayChild> = {
	components: [
		{
			type: ComponentType.Container,
			layout: {
				dimensions: {
					widthPercent: 100,
					heightPercent: 100
				},
				childLayout: {
					mode: LayoutMode.Vertical,
					hAlign: HAlign.Center,
					vAlign: VAlign.Middle,
					padding: 1
				}
			},
			background: {
				type: BackgroundType.Color,
				color: 0x181637,
				gradient: {
					colorStops: [
						{ offset: 0, color: "#0c0c1e" },
						{ offset: 0.5, color: "#181637" },
						{ offset: 1, color: "#0a0c1a" }
					],
					direction: GradientDirection.ToBottom
				}
			},
			children: [
				{
					type: ComponentType.Sprite,
					src: "assets/images/logo.png",
					layout: {
						dimensions: { width: 381, height: 43, widthPercent: 35 },
						offset: {
							yPercent: -5
						}
					}
				},
				{
					id: Components.LoadingBar,
					type: ComponentType.LoadingBar,
					layout: {
						dimensions: {
							width: 200,
							height: 4,
							widthPercent: 35
						}
					},
					barColor: 0xffffff,
					trackColor: 0x17152e,
					cornerRadius: 2
				}
			]
		},
		{
			id: Components.VersionNumber,
			type: ComponentType.Text,
			text: "",
			layout: {
				dimensions: {
					widthPercent: 6,
					heightPercent: 3
				},
				offset: {
					yPercent: -2,
					xPercent: 2
				},
				vAlign: VAlign.Bottom
			},
			style: {
				fontFamily: ["Roboto", "sans-serif"],
				fill: 0xaaaaaa,
				align: TextAlign.Left
			}
		},
		SoundDialog,
		PreloadErrorDialog
	],
	controllers: [Controllers.Preload]
};
