import { ComponentType, ContainerConfig, HAlign, TextAlign, VAlign } from "@tournament/ui-core";
import { Components, HiLoComponentType, HiLoDisplayChild, HiLoEvents } from "@tournament/hilo-ui-core";

export const DownButton: ContainerConfig<HiLoDisplayChild, HiLoComponentType> = {
	type: ComponentType.Container,
	layout: {
		dimensions: { heightPercent: 110, widthPercent: 30 },
		vAlign: VAlign.Middle,
		hAlign: HAlign.Right,
		offset: {
			yPercent: -5
		}
	},
	children: [
		{
			id: Components.NumUsersDownBackground,
			type: ComponentType.Sprite,
			src: "UsersDown-Background",
			layout: {
				dimensions: { width: 230, height: 61, widthPercent: 100, heightPercent: 15 },
				offset: {
					yPercent: -7
				},
				hAlign: HAlign.Center
			}
		},
		{
			id: Components.NumUsersDownText,
			type: ComponentType.Text,
			text: "",
			style: {
				fontFamily: ["Roboto", "sans-serif"],
				fontWeight: "700",
				fill: 0xffffff,
				align: TextAlign.Center
			},
			layout: {
				dimensions: { width: 356, height: 114, widthPercent: 80, heightPercent: 12 },
				offset: {
					yPercent: -5.5
				},
				hAlign: HAlign.Center
			}
		},
		{
			id: Components.LowerButton,
			type: ComponentType.Button,
			textures: {
				up: "DownButton-Up",
				over: "DownButton-Over",
				down: "DownButton-Down",
				disabled: "DownButton-Disabled"
			},
			clickEvent: HiLoEvents.User.ChooseLower,
			layout: {
				dimensions: { width: 269, height: 246, heightPercent: 56, widthPercent: 100 },
				offset: {
					yPercent: 14
				},
				hAlign: HAlign.Center
			}
		},
		{
			type: ComponentType.Text,
			text: {
				id: "lower"
			},
			style: {
				fontFamily: ["Roboto", "sans-serif"],
				fontWeight: "500",
				fill: 0xffffff,
				align: TextAlign.Center
			},
			layout: {
				dimensions: { widthPercent: 100, heightPercent: 18 },
				offset: {
					yPercent: 76
				},
				hAlign: HAlign.Center
			}
		}
	]
};
