import { AssetConfig } from "@tournament/ui-core";

export const HiLoAssetConfig: AssetConfig = {
	fonts: {
		RobotoBold: "assets/fonts/Roboto-Bold.ttf",
		RobotoRegular: "assets/fonts/Roboto-Regular.ttf"
	},
	images: {
		Background: "assets/images/background.json",
		Numbers: "assets/images/numbers.json",
		GameUI: "assets/images/ui.json",
		Animations: "assets/images/animations.json",
		GameOverDialog: "assets/images/GameOverDialog.png",
		GameOverDialogButton: "assets/images/GameOverDialogButton.png",
		GameOverTransition: "assets/images/gameovertransition.json",
		ShineAnimation: "assets/images/shine.json",
		LastNumbersAnim: "assets/images/last-numbers-anim.json",
		LeaderBoard: "assets/images/leaderboard.json",
		LeaderBoardButton: "assets/images/leaderboard-button.json",
		LastNumbersBackground: "assets/images/lastnumbers_background.png",
		CountDownBackground: "assets/images/countdown_background.png"
	},
	sounds: {
		ButtonClick: {
			src: [
				"assets/sounds/click_sound_close_mute.mp3",
				"assets/sounds/click_sound_close_mute.ogg",
				"assets/sounds/click_sound_close_mute.aac"
			]
		},
		HiLoClick: {
			src: [
				"assets/sounds/click_sound_hi_low.mp3",
				"assets/sounds/click_sound_hi_low.ogg",
				"assets/sounds/click_sound_hi_low.aac"
			]
		},
		LoseSound: {
			src: ["assets/sounds/lose_sound.mp3", "assets/sounds/lose_sound.ogg", "assets/sounds/lose_sound.aac"]
		},
		AnticipationMusic: {
			src: [
				"assets/sounds/music_anticipation_layer.mp3",
				"assets/sounds/music_anticipation_layer.ogg",
				"assets/sounds/music_anticipation_layer.aac"
			]
		},
		BackgroundMusic: {
			src: [
				"assets/sounds/music_background.mp3",
				"assets/sounds/music_background.ogg",
				"assets/sounds/music_background.aac"
			]
		},
		RoundComplete: {
			src: [
				"assets/sounds/sounds_round_finish.mp3",
				"assets/sounds/sounds_round_finish.ogg",
				"assets/sounds/sounds_round_finish.aac"
			]
		},
		StartGame: {
			src: [
				"assets/sounds/welcome_sound.mp3",
				"assets/sounds/welcome_sound.ogg",
				"assets/sounds/welcome_sound.aac"
			]
		},
		WinSound: {
			src: ["assets/sounds/win_sound.mp3", "assets/sounds/win_sound.ogg", "assets/sounds/win_sound.aac"]
		}
	}
};
