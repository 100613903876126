import { CloseButton } from "./components/CloseButton";
import { SoundButton } from "./components/SoundButton";
import { Timer } from "./components/Timer";
import { GameOverDialog } from "./components/GameOverDialog";
import { PrizeWinDialog } from "./components/PrizeWinDialog";
import { ErrorDialog } from "./components/ErrorDialog";
import { ObserverGameOverDialog } from "./components/ObserverGameOverDialog";
import { ObserverGameWonDialog } from "./components/ObserverGameWonDialog";
import { LastManGameInfoDialog } from "./components/LastManGameInfoDialog";
import { SurvivalGameInfoDialog } from "./components/SurvivalGameInfoDialog";
import {
	BackgroundType,
	ComponentType,
	ContainerConfig,
	HAlign,
	LayoutConfig,
	LayoutMode,
	LayoutType,
	TextAlign,
	TextFieldConfig,
	VAlign
} from "@tournament/ui-core";
import { Components, Controllers, HiLoComponentType, HiLoDisplayChild } from "@tournament/hilo-ui-core";
import { LeaderboardGameInfoDialog } from "./components/LeaderboardGameInfoDialog";
import { LeaderboardGameOverDialog } from "./components/LeaderboardGameOverDialog";
import { LeaderboardNoMoreEntriesDialog } from "./components/LeaderboardNoMoreEntriesDialog";
import { LeaderboardTournamentEndedDialog } from "./components/LeaderboardTournamentEndedDialog";
import { filters } from "pixi.js";

const SoundButtonContainer: ContainerConfig<HiLoDisplayChild, HiLoComponentType> = {
	type: ComponentType.Container,
	layout: {
		dimensions: { heightPercent: 75, widthPercent: 20 }
	},
	children: [SoundButton]
};

const CloseButtonContainer: ContainerConfig<HiLoDisplayChild, HiLoComponentType> = {
	type: ComponentType.Container,
	layout: {
		dimensions: { heightPercent: 75, widthPercent: 20 }
	},
	children: [CloseButton]
};

const GameTitleMessage: TextFieldConfig<HiLoComponentType> = {
	id: Components.GameTitleText,
	type: ComponentType.Text,
	layout: {
		hAlign: HAlign.Center,
		dimensions: { widthPercent: 94, heightPercent: 30 }
	},
	text: "",
	style: {
		fontFamily: ["Roboto", "sans-serif"],
		fontWeight: "500",
		fill: 0xffffff,
		align: TextAlign.Center
	}
};

const RoundMessage: TextFieldConfig<HiLoComponentType> = {
	id: Components.RoundsText,
	type: ComponentType.Text,
	layout: {
		hAlign: HAlign.Center,
		dimensions: { widthPercent: 60, heightPercent: 25 }
	},
	text: "",
	style: {
		fontFamily: ["Roboto", "sans-serif"],
		fontWeight: "500",
		fill: 0xffffff,
		align: TextAlign.Center
	}
};

const NumRemainingMessage: TextFieldConfig<HiLoComponentType> = {
	id: Components.NumRemainingText,
	type: ComponentType.Text,
	layout: {
		hAlign: HAlign.Center,
		dimensions: { widthPercent: 80, heightPercent: 18 }
	},
	text: "",
	style: {
		fontFamily: ["Roboto", "sans-serif"],
		fill: 0xffffff,
		align: TextAlign.Center
	}
};

const Title: ContainerConfig<HiLoDisplayChild, HiLoComponentType> = {
	type: ComponentType.Container,
	layout: {
		childLayout: {
			mode: LayoutMode.Vertical,
			vAlign: VAlign.Middle
		},
		vAlign: VAlign.Middle,
		hAlign: HAlign.Center,
		dimensions: { heightPercent: 100, widthPercent: 60 },
		offset: {
			yPercent: -1
		}
	},
	children: [GameTitleMessage, RoundMessage, NumRemainingMessage]
};

const TitleContainer: ContainerConfig<HiLoDisplayChild, HiLoComponentType> = {
	type: ComponentType.Container,
	layout: {
		childLayout: {
			mode: LayoutMode.Horizontal
		},
		dimensions: { widthPercent: 100, heightPercent: 16 }
	},
	children: [CloseButtonContainer, Title, SoundButtonContainer]
};

const PreviousNumbersContainer: ContainerConfig<HiLoDisplayChild, HiLoComponentType> = {
	type: ComponentType.Container,
	layout: {
		dimensions: { widthPercent: 100, heightPercent: 7 }
	},
	children: [
		{
			id: Components.PreviousNumberBackground,
			type: ComponentType.Sprite,
			src: "LastNumbersBackground",
			alpha: 0.1,
			layout: {
				dimensions: { widthPercent: 60, heightPercent: 70 },
				vAlign: VAlign.Middle,
				hAlign: HAlign.Left
			}
		},
		{
			id: Components.PreviousNumberContainer,
			type: ComponentType.Container,
			layout: {
				dimensions: { widthPercent: 50, heightPercent: 100 },
				hAlign: HAlign.Left,
				childLayout: {
					mode: LayoutMode.Horizontal,
					hAlign: HAlign.Right,
					padding: 1
				}
			}
		},
		{
			id: Components.TournamentCountDownContainer,
			type: ComponentType.Container,
			visible: false,
			layout: {
				dimensions: { widthPercent: 30, heightPercent: 70 },
				hAlign: HAlign.Right,
				vAlign: VAlign.Middle
			},
			children: [
				{
					type: ComponentType.Sprite,
					src: "CountDownBackground",
					alpha: 0.1,
					layout: {
						dimensions: { widthPercent: 100, heightPercent: 100 },
						vAlign: VAlign.Middle,
						hAlign: HAlign.Right
					}
				},
				{
					id: Components.FinalRoundMessage,
					type: ComponentType.Text,
					text: {
						id: "finalRoundMessage"
					},
					visible: false,
					style: {
						fontFamily: ["Roboto", "sans-serif"],
						fontWeight: "500",
						fill: 0xffffff,
						align: TextAlign.Right
					},
					layout: {
						dimensions: {
							widthPercent: 90,
							heightPercent: 90
						},
						offset: {
							xPercent: -5
						},
						hAlign: HAlign.Right,
						vAlign: VAlign.Middle
					}
				},
				{
					id: Components.TournamentCountDownTitle,
					type: ComponentType.Text,
					text: {
						id: "tournamentEnds"
					},
					style: {
						fontFamily: ["Roboto", "sans-serif"],
						fontWeight: "500",
						fill: 0xffffff,
						align: TextAlign.Right
					},
					layout: {
						dimensions: {
							widthPercent: 90,
							heightPercent: 40
						},
						offset: {
							xPercent: -5,
							yPercent: 2
						},
						hAlign: HAlign.Right,
						vAlign: VAlign.Top
					}
				},
				{
					id: Components.TournamentCountDownTimer,
					type: ComponentType.Text,
					text: "03:47:14",
					style: {
						fontFamily: ["Roboto", "sans-serif"],
						fontWeight: "500",
						fill: 0xffffff,
						align: TextAlign.Right
					},
					layout: {
						dimensions: {
							widthPercent: 60,
							heightPercent: 58
						},
						offset: {
							xPercent: -5,
							yPercent: -2
						},
						hAlign: HAlign.Right,
						vAlign: VAlign.Bottom
					}
				}
			]
		}
	]
};

const purpleFilter: filters.ColorMatrixFilter = new filters.ColorMatrixFilter();
const greenFilter: filters.ColorMatrixFilter = new filters.ColorMatrixFilter();

purpleFilter.hue(52, false);
greenFilter.hue(-94, false);

export const HiLoConfig: LayoutConfig<HiLoDisplayChild> = {
	components: [
		{
			id: Components.GameContainer,
			type: ComponentType.Container,
			background: {
				type: BackgroundType.Color,
				color: 0x000000
			},
			layout: {
				dimensions: { widthPercent: 100, heightPercent: 100 }
			},
			children: [
				{
					id: Components.BackgroundContainer,
					type: ComponentType.Container,
					layout: {
						dimensions: { widthPercent: 100, heightPercent: 100 }
					},
					children: [
						{
							type: ComponentType.Container,
							background: {
								type: BackgroundType.Image,
								src: "Background",
								dimensions: { width: 1280, height: 1280 },
								layoutType: LayoutType.OverflowX
							},
							filters: [purpleFilter],
							layout: {
								dimensions: { widthPercent: 100, heightPercent: 100 },
								hAlign: HAlign.Center,
								vAlign: VAlign.Bottom
							}
						},
						{
							id: Components.HigherBackground,
							type: ComponentType.Container,
							background: {
								type: BackgroundType.Image,
								src: "Background",
								dimensions: { width: 1280, height: 1280 },
								layoutType: LayoutType.OverflowX
							},
							alpha: 0,
							filters: [greenFilter],
							layout: {
								dimensions: { widthPercent: 100, heightPercent: 100 },
								hAlign: HAlign.Center,
								vAlign: VAlign.Bottom
							}
						},
						{
							id: Components.LowerBackground,
							type: ComponentType.Container,
							background: {
								type: BackgroundType.Image,
								src: "Background",
								dimensions: { width: 1280, height: 1280 },
								layoutType: LayoutType.OverflowX
							},
							alpha: 0,
							layout: {
								dimensions: { widthPercent: 100, heightPercent: 100 },
								hAlign: HAlign.Center,
								vAlign: VAlign.Bottom
							}
						}
					]
				},
				{
					type: ComponentType.Container,
					background: {
						type: BackgroundType.Image,
						src: "Background-Fade",
						dimensions: { width: 1280, height: 1280 },
						layoutType: LayoutType.OverflowX
					},
					layout: {
						dimensions: { widthPercent: 100, heightPercent: 100 },
						hAlign: HAlign.Center,
						vAlign: VAlign.Bottom
					}
				},
				{
					type: ComponentType.Container,
					layout: {
						hAlign: HAlign.Center,
						dimensions: { width: 100, height: 125, widthPercent: 100, heightPercent: 100 },
						childLayout: {
							mode: LayoutMode.Vertical,
							vAlign: VAlign.Middle,
							hAlign: HAlign.Center
						}
					},
					children: [
						{
							id: Components.WinDialogAnimContainer,
							type: ComponentType.Container,
							layout: {
								dimensions: { widthPercent: 100, heightPercent: 100 }
							}
						},
						{
							id: Components.ParticleAnimContainer,
							type: ComponentType.Container,
							layout: {
								dimensions: { widthPercent: 100, heightPercent: 100 }
							}
						},
						{
							id: Components.TimerAnimContainer,
							type: ComponentType.Container,
							layout: {
								dimensions: { widthPercent: 100, heightPercent: 100 }
							}
						}
					]
				},
				{
					id: Components.ChoiceAnimContainer,
					type: ComponentType.Container,
					layout: {
						dimensions: { widthPercent: 100, heightPercent: 100 }
					}
				},
				{
					type: ComponentType.Container,
					layout: {
						childLayout: {
							mode: LayoutMode.Vertical
						},
						dimensions: { widthPercent: 100, heightPercent: 100 }
					},
					children: [TitleContainer, PreviousNumbersContainer, Timer]
				},
				{
					id: Components.BackgroundAnimContainer,
					type: ComponentType.Container,
					layout: {
						dimensions: { widthPercent: 100, heightPercent: 100 }
					}
				}
			]
		},
		LastManGameInfoDialog,
		SurvivalGameInfoDialog,
		LeaderboardGameInfoDialog,
		ObserverGameOverDialog,
		ObserverGameWonDialog,
		GameOverDialog,
		LeaderboardGameOverDialog,
		PrizeWinDialog,
		LeaderboardNoMoreEntriesDialog,
		LeaderboardTournamentEndedDialog,
		ErrorDialog
	],
	controllers: [
		Controllers.UI,
		Controllers.BackgroundAnimation,
		Controllers.Choice,
		Controllers.LastNumbers,
		Controllers.TimerAnimation,
		Controllers.Sound,
		Controllers.Leaderboard
	]
};
