import { injectable } from "inversify";
import { AnimatedSprite } from "pixi.js";
import {
	Events,
	FixedDimensions,
	GraphicsUtils,
	LayoutSprite,
	lazyInject,
	PixiTween,
	TweenProperty
} from "@tournament/ui-core";
import { AbstractBackgroundAnimController, Components, Direction, HiLoEvents } from "@tournament/hilo-ui-core";

@injectable()
export class BackgroundAnimController extends AbstractBackgroundAnimController {
	protected gameOverBackgroundAnimation!: AnimatedSprite;

	@lazyInject(Components.HigherBackground)
	protected higherBackground!: LayoutSprite;

	@lazyInject(Components.LowerBackground)
	protected lowerBackground!: LayoutSprite;

	public constructor() {
		super();

		this.animContainer.onResize = this.onContainerResized;
	}

	public onContainerResized = (dimensions: FixedDimensions): void => {
		if (this.gameOverBackgroundAnimation) {
			this.gameOverBackgroundAnimation.height = this.animContainer.getHeight() || 1;
			this.gameOverBackgroundAnimation.width = this.animContainer.getWidth() || 1;
		}
	};

	protected setUserChoice(direction: Direction): void {
		if (direction === Direction.Higher) {
			this.backgroundContainer.setChildIndex(this.higherBackground, this.backgroundContainer.children.length - 1);
			this.lowerBackground.alpha = 0;
			this.higherBackground.alpha = 1;
		} else {
			this.backgroundContainer.setChildIndex(this.lowerBackground, this.backgroundContainer.children.length - 1);
			this.higherBackground.alpha = 0;
			this.lowerBackground.alpha = 1;
		}
	}

	protected playGameOverAnimation(): void {
		this.gameOverBackgroundAnimation = new AnimatedSprite(
			GraphicsUtils.createTextureSequence("GameOverTransition", 35).reverse()
		);
		this.gameOverBackgroundAnimation.animationSpeed = 1;
		this.gameOverBackgroundAnimation.height = this.animContainer.getHeight();
		this.gameOverBackgroundAnimation.width = this.animContainer.getWidth();
		this.gameOverBackgroundAnimation.loop = false;

		this.animContainer.addChild(this.gameOverBackgroundAnimation);

		this.gameOverBackgroundAnimation.onComplete = () => {
			this.animContainer.removeChild(this.gameOverBackgroundAnimation);
		};

		this.gameOverBackgroundAnimation.play();
	}

	protected clearBackgroundAnimations(): void {
		this.animContainer.removeChild(this.gameOverBackgroundAnimation);
	}

	protected onGameOver(): void {
		this.gameOverBackgroundAnimation = new AnimatedSprite(
			GraphicsUtils.createTextureSequence("GameOverTransition", 35)
		);
		this.gameOverBackgroundAnimation.animationSpeed = 0.5;
		this.gameOverBackgroundAnimation.height = this.animContainer.getHeight();
		this.gameOverBackgroundAnimation.width = this.animContainer.getWidth();
		this.gameOverBackgroundAnimation.loop = false;

		this.animContainer.addChild(this.gameOverBackgroundAnimation);

		this.gameOverBackgroundAnimation.onComplete = () => {
			if (!this.isObserving) {
				if (this.model.winners && this.model.winners.length > 0 && this.model.winAmount != undefined) {
					// Prize win dialog
					this.eventBus.once(HiLoEvents.Animation.ShowPrizeWinAnimation, this.animatePrizeWinGlow, this);
					this.eventBus.emit(HiLoEvents.Game.ShowPrizeWinDialog);
				} else {
					this.eventBus.emit(Events.Sound.Play, "LoseSound");
					this.eventBus.emit(HiLoEvents.Game.ShowGameOverDialog);
				}
			} else if (this.model.winners && this.model.winners.length > 0) {
				this.eventBus.emit(HiLoEvents.Game.ShowObserveWinDialog);
			} else {
				this.eventBus.emit(Events.Sound.Play, "LoseSound");
				this.eventBus.emit(HiLoEvents.Game.ShowObserveLoseDialog);
			}
		};
		this.gameOverBackgroundAnimation.play();
	}

	protected animatePrizeWinGlow(): void {
		this.backgroundAnimation = new AnimatedSprite(GraphicsUtils.createTextureSequence("Shine", 180));
		this.backgroundAnimation.alpha = 0.7;
		const ratio: number = this.backgroundAnimation.width / this.backgroundAnimation.height;

		this.backgroundAnimation.width = this.animContainer.width;
		this.backgroundAnimation.height = this.backgroundAnimation.width / ratio;

		this.backgroundAnimation.x = 0;
		this.backgroundAnimation.y = (this.animContainer.height - this.backgroundAnimation.height) / 2;

		this.backgroundAnimation.loop = true;

		this.winDialogAnimContainer.addChild(this.backgroundAnimation);
		this.winDialogAnimContainer.onResize = (dimensions: FixedDimensions): void => {
			if (this.backgroundAnimation) {
				this.backgroundAnimation.width = this.animContainer.width;
				this.backgroundAnimation.height = this.backgroundAnimation.width / ratio;

				this.backgroundAnimation.x = 0;
				this.backgroundAnimation.y = (this.animContainer.height - this.backgroundAnimation.height) / 2;
			}
		};
		this.backgroundAnimation.play();
	}

	protected onChooseHigher(): void {
		this.clearCurrentAnimations();

		if (this.lowerBackground.alpha > 0) {
			this.backgroundContainer.setChildIndex(this.higherBackground, this.backgroundContainer.children.length - 1);
			this.higherBackground.alpha = 0;
		}

		const tween: PixiTween = new PixiTween([this.higherBackground], TweenProperty.Alpha, this).fromTo(0, 1, 15);

		tween.once(
			Events.Animation.Complete,
			() => {
				this.onAnimationComplete(tween);
			},
			this
		);

		this.animations.push(tween);
		tween.play();
	}

	protected onChooseLower(): void {
		this.clearCurrentAnimations();

		if (this.higherBackground.alpha > 0) {
			this.backgroundContainer.setChildIndex(this.lowerBackground, this.backgroundContainer.children.length - 1);
			this.lowerBackground.alpha = 0;
		}

		const tween: PixiTween = new PixiTween([this.lowerBackground], TweenProperty.Alpha, this).fromTo(0, 1, 15);

		tween.once(
			Events.Animation.Complete,
			() => {
				this.onAnimationComplete(tween);
			},
			this
		);

		this.animations.push(tween);
		tween.play();
	}

	protected returnToOriginalColors(): void {
		this.clearCurrentAnimations();

		if (this.higherBackground.alpha > 0) {
			const tween: PixiTween = new PixiTween([this.higherBackground], TweenProperty.Alpha, this).to(0, 15);
			tween.once(
				Events.Animation.Complete,
				() => {
					this.onAnimationComplete(tween);
				},
				this
			);
			this.animations.push(tween);
			tween.play();
		}

		if (this.lowerBackground.alpha > 0) {
			const tween: PixiTween = new PixiTween([this.lowerBackground], TweenProperty.Alpha, this).to(0, 15);
			tween.once(
				Events.Animation.Complete,
				() => {
					this.onAnimationComplete(tween);
				},
				this
			);
			this.animations.push(tween);
			tween.play();
		}
	}

	protected onChoiceFailed(): void {
		console.log("Choice failed");
	}
}
