import { ComponentType, HAlign, LayoutMode, NineSliceSpriteConfig, TextAlign, VAlign } from "@tournament/ui-core";
import { Components, HiLoComponentType, HiLoDisplayChild, HiLoEvents } from "@tournament/hilo-ui-core";

export const LeaderboardNoMoreEntriesDialog: NineSliceSpriteConfig<
	HiLoDisplayChild,
	HiLoComponentType
> = {
	id: Components.LeaderboardNoMoreEntriesDialog,
	type: ComponentType.NineSliceSprite,
	slices: {
		texture: "GameOverDialog",
		leftWidth: 23,
		topHeight: 23,
		rightWidth: 23,
		bottomHeight: 23
	},
	visible: false,
	layout: {
		vAlign: VAlign.Middle,
		hAlign: HAlign.Center,
		dimensions: {
			width: 800,
			height: 400,
			widthPercent: 85,
			heightPercent: 50
		}
	},
	children: [
		{
			type: ComponentType.Text,
			text: {
				id: "noMoreTries"
			},
			style: {
				fontFamily: ["Roboto", "sans-serif"],
				fontWeight: "500",
				fill: 0xffffff,
				align: TextAlign.Center
			},
			layout: {
				dimensions: {
					widthPercent: 90,
					heightPercent: 16
				},
				offset: {
					yPercent: 6
				},
				hAlign: HAlign.Center
			}
		},
		{
			type: ComponentType.Text,
			text: {
				id: "tournamentEnds"
			},
			style: {
				fontFamily: ["Roboto", "sans-serif"],
				fontWeight: "500",
				fill: 0xffffff,
				align: TextAlign.Center
			},
			layout: {
				dimensions: {
					widthPercent: 90,
					heightPercent: 24
				},
				offset: {
					yPercent: 20
				},
				hAlign: HAlign.Center
			}
		},
		{
			id: Components.TournamentEndCountdown,
			type: ComponentType.Text,
			text: "",
			style: {
				fontFamily: ["Roboto", "sans-serif"],
				fontWeight: "500",
				fill: 0xffffff,
				align: TextAlign.Center
			},
			layout: {
				dimensions: {
					widthPercent: 80,
					heightPercent: 36
				},
				offset: {
					yPercent: 38
				},
				hAlign: HAlign.Center
			}
		},
		{
			id: Components.LeaderboardCurrentRanking,
			type: ComponentType.Text,
			text: "",
			style: {
				fontFamily: ["Roboto", "sans-serif"],
				fontWeight: "500",
				fill: 0xffffff,
				align: TextAlign.Center
			},
			layout: {
				dimensions: {
					widthPercent: 90,
					heightPercent: 14
				},
				offset: {
					yPercent: 72
				},
				hAlign: HAlign.Center
			}
		},
		{
			type: ComponentType.Container,
			layout: {
				dimensions: {
					widthPercent: 100,
					heightPercent: 16
				},
				offset: {
					yPercent: 4
				},
				hAlign: HAlign.Center,
				vAlign: VAlign.Bottom,
				childLayout: {
					mode: LayoutMode.Horizontal,
					padding: 5,
					vAlign: VAlign.Middle,
					hAlign: HAlign.Center
				}
			},
			children: [
				{
					id: Components.GameOverConfirm,
					type: ComponentType.NineSliceButton,
					textures: {
						up: "GameOverDialogButton",
						over: "GameOverDialogButton",
						down: "GameOverDialogButton",
						disabled: "GameOverDialogButton"
					},
					clickEvent: HiLoEvents.User.Close,
					slices: {
						top: 10,
						left: 10,
						right: 10,
						bottom: 10
					},
					layout: {
						dimensions: {
							widthPercent: 36,
							heightPercent: 100
						}
					},
					children: [
						{
							type: ComponentType.Text,
							text: {
								id: "returnToLobby"
							},
							style: {
								fontFamily: ["Roboto", "sans-serif"],
								fontWeight: "500",
								fill: 0xf5f0fb,
								align: TextAlign.Center
							},
							layout: {
								dimensions: {
									widthPercent: 85,
									heightPercent: 85
								},
								vAlign: VAlign.Middle,
								hAlign: HAlign.Center
							}
						}
					]
				}
			]
		}
	]
};
